<template>
  <div class="Media-Library">
    
    <div class="page-header">
      <h3 class="page-title">Media Library</h3>
      <nav aria-label="breadcrumb">
        <div class="float-right">
          <router-link to="/media/add-media-library" class="btn btn btn-add btn-primary">Add New
          </router-link>
        </div>
      </nav>
    </div>
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-3 col-md-2 col-lg-1"></div>
              <div class="col-9 col-md-3 col-lg-2 pl-2 pr-2 d-pr-0-sm">
                <select class="custom-select form-control" @change="sortByType" v-model="type">
                  <option value="" selected>All media items</option>
                  <option value="image">Image</option>
                  <option value="doc">Doc</option>
                  <option value="video">Video</option>
                  <option value="audio">Audio</option>
                </select>
              </div>
              <div class="col-6 col-md-3 col-lg-2 pl-2 pr-2 d-pl-0-sm sm-mt-2">
                <date-picker @change="sortByDate" v-model="date" format="YYYY-MM-DD" placeholder="Select Date"
                  valueType="format"></date-picker>
              </div>

              <div class="col-0 col-md-1 col-lg-4 pl-2 pr-2 d-none-sm"></div>
              <div class="col-6 col-md-3 col-lg-3 d-pl-20-sm sm-mt-2">
                <div class="d-flex align-items-center input-group search-field">
                  <div class="input-group-prepend bg-transparent">
                    <i class="input-group-text border-0 mdi mdi-magnify"></i>
                  </div>
                  <input v-model="searchKey" type="text" v-on:keyup="search()" class="
                      form-control
                      bg-transparent
                      border-0
                      search-border-left
                    " placeholder="Search...." />
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="p-2">
              <b-tabs align="right">
                <b-tab active class="p-0">
                  <template #title>
                    <div class="tab-icon"><i class="fa fa-th-large"></i></div>
                  </template>
                  <div class="row">
                    <div class="col-6 col-sm-4 col-md-3 col-lg-2 mb-3 pr-2 pl-2" v-for="item in info" :key="item.id">
                      <router-link :to="`/media/attachment-document/${item.id}`">
                        <div v-if="item.type === 'image'" class="product-media">
                          <img :src="`${imageApiURL}/${item.thumb_m}`" class="show-img img-fluid" />
                        </div>
                        <div v-if="item.type !== 'image'">
                          <img :src="`${imageApiURL}/${item.thumbs.thumb_m}`" class="show-img img-fluid" />
                        </div>
                      </router-link>
                    </div>
                    <div class="col-12 pb-3 text-center" v-if="info == ''">
                      No data found!
                    </div>
                  </div>
                </b-tab>

                <b-tab class="p-0">
                  <template #title>
                    <div class="tab-icon"><i class="fa fa-list-ul"></i></div>
                  </template>
                  <div class="row">
                    <div class="col-md-12 pr-2 pl-2">
                      <div class="table-responsive mb-0 border">
                        <table class="table table-centered table-nowrap">
                          <thead>
                            <tr>
                              <th>Type</th>
                              <th>Name</th>
                              <th>Thumbnail</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="item in info" :key="item.id">
                              <td>{{ item.type }}</td>
                              <td>{{ item.name }}</td>

                              <td>
                                <div v-if="item.type === 'image'">
                                  <img :src="`${imageApiURL}/${item.thumb_s}`" class="show-img img-fluid" />
                                </div>
                                <div v-if="item.type !== 'image'">
                                  <img :src="`${imageApiURL}/${item.thumbs.thumb_s}`" class="show-img img-fluid" />
                                </div>
                              </td>
                              <td>
                                <router-link class="mr-3 text-info" :to="`/media/attachment-document/${item.id}`">
                                  <i class="mdi mdi-pencil font-size-18"></i>
                                </router-link>
                                <a v-on:click="deleteFile(item.id)" class="text-primary">
                                  <i class="mdi mdi-window-close font-size-18"></i>
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div class="col-12 pt-4 text-center" v-if="info == ''">
                      No data found!
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
          <div style="border-top: 1px solid #e9ecef">
            <!-- <ul class="pagination pagination-rounded justify-content-end m-3">
              <li class="page-item">
                <a @click.prevent="onClickPage(1)" class="page-link" href="javascript: void(0);" aria-label="Previous">
                  <i class="mdi mdi-chevron-left"></i>
                </a>
              </li>
              <li class="page-item" :class="{ active: isPageActive(item) }" v-for="item in totalPage" :key="item">
                <a class="page-link" @click.prevent="onClickPage(item)" href="javascript: void(0);">{{ item }}</a>
              </li>

              <li class="page-item">
                <a @click.prevent="onClickPage(totalPage)" class="page-link" href="javascript: void(0);"
                  aria-label="Next">
                  <i class="mdi mdi-chevron-right"></i>
                </a>
              </li>
            </ul> -->
            <paginate  :page-count="totalPage" :page-range="3" :margin-pages="1"
              :click-handler="clickCallback" :prev-text="'Prev'" :next-text="'Next'" :container-class="'pagination'"
              :page-class="'page-item'">
            </paginate>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import axios from "axios";

const imageApiURL = process.env.VUE_APP_IMAGE_URL;
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
import Paginate from 'vuejs-paginate'
export default {
  name: "media_library",
  components: {
    
    DatePicker,Paginate
  },
  data() {
    return {
      info: null,
      totalPage: this.totalPage,
      
      imageApiURL,
      searchKey: "",
      type: "",
      date: "",
      id: "",
    };
  },
  computed: {},
  mounted() {
    let page = 1;
    this.list(page);
  },
  methods: {
    list(page) {
      
      axios.get(`${mediaApi}/files?page=${page}`).then((response) => {
        this.info = response.data.data.data;
        
        this.totalPage = response.data.data.last_page;
        this.current_page = response.data.data.current_page;
      });
    },
    clickCallback: function (page) {
      this.list(page) 
    },
    onClickPage(item) {
      if (this.searchKey !== "") {
        this.search(item);
      } else if (this.type !== "") {
        this.sortByType(item);
      } else if (this.date !== "") {
        this.sortByDate(item);
      } else {
        this.list(item);
      }
    },
    isPageActive(item) {
      return this.current_page === item;
    },
    search(item) {

      var parameter = {
        name: this.searchKey, type: this.type, date: this.date
      };
      var text = Object.keys(parameter).forEach(k => (parameter[k] === undefined || parameter[k] === null || parameter[k] === '') && delete parameter[k]);
      var key = new URLSearchParams(parameter).toString()
      
      axios
        .get(`${mediaApi}/search?${key}&page=${item}`)
        .then((response) => {
          this.info = response.data.data.data;
          
          this.totalPage = response.data.data.last_page;
          this.current_page = response.data.data.current_page;
        });
    },
    sortByType(item) {

      var parameter = {
        name: this.searchKey, type: this.type, date: this.date
      };
      var text = Object.keys(parameter).forEach(k => (parameter[k] === undefined || parameter[k] === null || parameter[k] === '') && delete parameter[k]);
      var type = new URLSearchParams(parameter).toString()
      
      axios
        .get(`${mediaApi}/search?${type}&page=${item}`)
        .then((response) => {
          this.info = response.data.data.data;
          
          this.totalPage = response.data.data.last_page;
          this.current_page = response.data.data.current_page;
        });

    },
    sortByDate(page) {
      page = 1
      var parameter = {
        name: this.searchKey, type: this.type, date: this.date
      };
      var text = Object.keys(parameter).forEach(k => (parameter[k] === undefined || parameter[k] === null || parameter[k] === '') && delete parameter[k]);
      var date = new URLSearchParams(parameter).toString()
      
      axios
        .get(`${mediaApi}/search?${date}&page=${page}`)
        .then((response) => {
          this.info = response.data.data.data;
          
          this.totalPage = response.data.data.last_page;
          this.current_page = response.data.data.current_page;
        });

    },
    deleteFile(id) {
      let body = { id: id };
      this.$swal({
        title: "Delete this File?",
        text: "Are you sure? You won't be able to revert this!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.post(`${mediaApi}/file/delete`, body).then((response) => {
            
            if (response.data.code == 200) {
              this.list(1);
              this.$swal({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                type: "success",
                title: " ",
                text: response.data.messages,
              });
            } else {
              this.$swal({
                type: "error",
                title: "Oops...",
                text: response.data.messages,
              });
            }
          });
        }
      });

    },
  },
};
</script>

